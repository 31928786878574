import React, {  } from 'react'
import { Career as CareerSections, CareerDetail as CareerDetailSections } from "src/assembled"
import { Footer } from "src/components"
import { SEO, useIsDeviceWidth } from "src/components/utils"


export default function CareerDetail({ location }) {
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })


    // seo
    const seo = <SEO
    title="Career"
    description="We're hiring. if you wanna ride a rocket, Join us!" 
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        {isSmallDevice && <CareerSections />}
        <CareerDetailSections />
    </main>    


    return <>
        {seo}
        {render}
        <Footer location={location} /> 
    </>
}
